const locationPricingTypes = {
  SET_PRICING_LOADING: "SET_PRICING_LOADING",
  RESET_PRICING_LOADING: "RESET_PRICING_LOADING",
  FETCH_PRICING_LOCATIONS: "FETCH_PRICING_LOCATIONS",
  FETCH_PRICING_ERROR: "FETCH_PRICING_ERROR",

  ADD_PRICING_LOCATION: "ADD_PRICING_LOCATION",
  UPDATE_PRICING_LOCATION: "UPDATE_PRICING_LOCATION",
  DELETE_PRICING_LOCATION: "DELETE_PRICING_LOCATION",

  SET_ACTIVE_PRICING: "SET_ACTIVE_PRICING",
  RESET_ACTIVE_PRICING: "RESET_ACTIVE_PRICING",
};

export default locationPricingTypes;
