/* eslint-disable no-console */
import { firebase } from "@config/firebaseConfig";
import { toastr } from "react-redux-toastr";

import tempDataConstants from "@store/constants/tempDataTypes";
import loaderConstants from "@store/constants/loadingTypes";
import authConstants from "@store/constants/authTypes";
import useIndexedDBWithBatchFetch from "@shared/hooks/useIndexedDBWithBatchFetch";

import { useAxiosGet, useAxiosPost } from "../../axios";
import { DB_TABLES } from "@constants/indexedDB";
import { segmentIdentify, segmentTrack } from "../../helpers/segment";
import userConstants from "../constants/userTypes";
import { Kustomer } from "../../helpers/kustomer";

export const setCustomers = (customers) => (dispatch) => {
  dispatch({ type: userConstants.SET_CUSTOMERS, payload: customers });
};

export const setWarriors = (warriors) => (dispatch) => {
  dispatch({ type: userConstants.SET_WARRIORS, payload: warriors });
};

export const updateWarrior = (warrior) => (dispatch) => {
  dispatch({ type: userConstants.UPDATE_WARRIOR, payload: warrior });
};

export const addUserState = (user) => (dispatch) => {
  dispatch({ type: authConstants.ADD_USER, payload: user });
};

export const updateUserState = (user) => (dispatch) => {
  dispatch({ type: authConstants.UPDATE_USER, payload: user });
  dispatch({ type: authConstants.SET_UPDATED_USER, payload: user });
};

export const deleteUserState = (user) => (dispatch) => {
  dispatch({ type: authConstants.DELETE_USER, payload: user });
};

export const updateProfile =
  (newProfile, credentials, showLoader = true, identifyUpdate = true) =>
  async (dispatch, getState) => {
    try {
      const user = getState().firebase.profile;
      const profile = { ...newProfile };

      if (newProfile.phoneNumber && newProfile.phoneNumber !== user?.phoneNumber) {
        const isValidPhone = await validatePhone(newProfile.phoneNumber);
        if (!isValidPhone) return;
      }

      if (credentials) {
        const { currentPassword, newEmail, newPassword } = credentials;
        if (user.email !== newEmail) {
          await updateUserEmail(newEmail, currentPassword);
          profile.email = newEmail;
        }
        if (newPassword) {
          await updateUserPassword(newPassword, currentPassword);
        }
      }

      if (showLoader) {
        dispatch({
          type: loaderConstants.SET_LOADING,
          isLoading: true,
          message: "Updating...",
        });
      }
      await useAxiosPost("/users/update-user", { uid: user.uid, ...profile });

      if (identifyUpdate) {
        segmentIdentify({
          email: profile.email ?? "",
          number: profile?.phoneNumber ?? "",
          firstName: profile?.firstName ?? "",
          lastName: profile?.lastName ?? "",
          isBusinessClient: !!user?.businessId ?? false,
          companyName: user?.companyName ?? "",
          businessId: user?.businessId ?? "",
          createdAt: user?.createdAt ?? "",
        });
      }

      showLoader && toastr.success("Success", "Details updated !");
    } catch (error) {
      console.log("updateProfile Error: ", error);
      toastr.error("Error", "Something went wrong");
    } finally {
      dispatch({ type: loaderConstants.RESET_LOADING });
    }
  };

export const verifyWarrior = (payload) => async (dispatch, getState) => {
  try {
    dispatch({
      type: loaderConstants.SET_LOADING,
      isLoading: true,
      message: "Updating...",
    });
    payload.lastAdminUpdate = {
      id: getState().firebase.profile.uid,
      email: getState().firebase.profile.email,
    };
    await useAxiosPost("/users/verify-warrior", payload);
    dispatch(updateWarrior({ id: payload?.uid, ...payload }));
    toastr.success("Success", "Warrior has been verified");
  } catch (error) {
    console.log("updateProfile Error: ", error);
    toastr.error("Error", "Could not verify warrior");
  } finally {
    dispatch({ type: loaderConstants.RESET_LOADING });
  }
};

export const updateProfileByAdmin = (payload) => async (dispatch, getState) => {
  try {
    dispatch({
      type: loaderConstants.SET_LOADING,
      isLoading: true,
      message: "Updating...",
    });
    payload.lastAdminUpdate = {
      id: getState().firebase.profile.uid,
      email: getState().firebase.profile.email,
    };
    await useAxiosPost("/users/update-user", payload);
    dispatch(updateWarrior({ id: payload?.uid, ...payload }));
    if (payload?.businessId) {
      segmentIdentify(
        {
          isBusinessClient: true,
          companyName: payload.companyName ?? "",
          businessId: payload.businessId ?? "",
        },
        payload?.uid
      );
    }
    toastr.success("Success", "Details updated !");
  } catch (error) {
    console.log("updateProfile Error: ", error);
    toastr.error("Error", "Something went wrong");
  } finally {
    dispatch({ type: loaderConstants.RESET_LOADING });
  }
};

export const validatePhone = async (phoneNumber) => {
  try {
    return await useAxiosPost("/users/check-unique-phone", { phoneNumber });
  } catch (error) {
    console.log("validatePhone Error: ", error);
  }
};

export const updateUserEmail = async (newEmail, currentPassword) => {
  try {
    let user = firebase.auth().currentUser;
    await reAuthUser(currentPassword);
    await user.updateEmail(newEmail);
  } catch (error) {
    console.log("updateUserEmail Error: ", error);
    throw new Error({ error });
  }
};

const updateUserPassword = async (newPassword, currentPassword) => {
  try {
    let user = firebase.auth().currentUser;
    await reAuthUser(currentPassword);
    await user.updatePassword(newPassword);
  } catch (error) {
    console.log("updateUserPassword Error: ", error);
    throw new Error({ error });
  }
};

const reAuthUser = (currentPassword) => {
  let user = firebase.auth().currentUser;
  let cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);

  return user.reauthenticateWithCredential(cred);
};

export const fetchUserProfileInfo = async (userId, adminEmail) => {
  try {
    const response = await useAxiosPost("/users/get-user", { userId, admin: adminEmail });
    return response.data.user;
  } catch (error) {
    console.log("Error fetching user profile info", error);
    return null;
  }
};

export const fetchWarriorStreak = async (uid) => {
  try {
    const res = await useAxiosGet(`/bonuses/fetch-warrior-streak/${uid}`);
    const { success, response, error } = res?.data;
    if (!success) {
      console.error(error);
      return null;
    }
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchUserProfile = (userId) => async (dispatch) => {
  try {
    const user = await fetchUserProfileInfo(userId);
    dispatch({
      type: tempDataConstants.SET_TEMP_USER,
      user: user,
    });
  } catch (error) {
    console.log(`Error fetching profile for ${userId}`, error);
  } finally {
    setTimeout(() => {
      dispatch({ type: loaderConstants.RESET_LOADING });
    }, 300);
  }
};

export const fetchWarriorProfile = (userId) => async (dispatch) => {
  try {
    const user = await fetchUserProfileInfo(userId);
    dispatch({
      type: authConstants.SET_WARRIOR,
      payload: user,
    });
  } catch (error) {
    console.log(`Error fetching profile for ${userId}`, error);
  }
};

export const resetWarriorProfile = () => async (dispatch) => {
  dispatch({
    type: authConstants.SET_WARRIOR,
    payload: null,
  });
};

export const fetchEUsers = async (query, envType) => {
  try {
    const response = await useAxiosPost("/users/e/get-users", { query, envType });
    return response.data.payload;
  } catch (error) {
    console.error("Error in fetchEUsers", error.message);
    toastr.error("Error", error.message || "Something went wrong !");
    return [];
  }
};

export const fetchUsers = async (userType) => {
  try {
    const response = await useAxiosGet(`/users/get-users?user_type=${userType}`);
    return response.data.payload;
  } catch (error) {
    console.error("Error in fetchUsers", error.message);
    toastr.error("Error", error.message || "Something went wrong !");
    return [];
  }
};

export const useFetchCustomersWithIDB = (settings) =>
  useIndexedDBWithBatchFetch(DB_TABLES.customers, {
    settings,
    URL: "/users/get-customers",
    SET_DATA: authConstants.SET_CUSTOMERS,
    SET_LOADER: authConstants.SET_USERS_LOADING,
    RESET_LOADER: authConstants.RESET_USERS_LOADING,
  });

export const useFetchProvidersWithIDB = (settings) =>
  useIndexedDBWithBatchFetch(DB_TABLES.providers, {
    settings,
    URL: "/users/get-providers",
    SET_DATA: authConstants.SET_PROVIDERS,
    SET_LOADER: authConstants.SET_USERS_LOADING,
    RESET_LOADER: authConstants.RESET_USERS_LOADING,
  });

// export const useFetchUsersWIthIDB = (settings) =>
//   useIndexedDBWithBatchFetch(DB_TABLES.users, {
//     settings,
//     URL: "/users/get-users",
//     SET_DATA: authConstants.SET_USERS,
//     SET_LOADER: authConstants.SET_USERS_LOADING,
//     RESET_LOADER: authConstants.RESET_USERS_LOADING,
//   });

export const chargeWarrior = async (form, callback) => {
  try {
    const response = await useAxiosPost("/users/charge-warrior", form);
    if (!response.data.success) {
      throw response?.data?.error?.raw?.message ?? "Something went wrong";
    }
    toastr.success("Done", `Warrior has been charged $${form.amount}`);
    callback?.();
  } catch (error) {
    toastr.error("Failed", `Error charging warrior ${error}`);
    callback?.(error);
  }
};

export const validateMigrationResetUser = async (email) => {
  try {
    const res = await useAxiosPost("/users/validate-user", { email });
    if (res.data.success) {
      return res.data.uid;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Something went wrong. ", error);
    return false;
  }
};

export const populateUserIds = async (users) => {
  try {
    const res = await useAxiosPost("/users/populate-users", { users });
    if (res.data.success) {
      return res.data.response;
    }
  } catch (error) {
    console.log("Something went wrong. ", error);
    return false;
  }
};

export const findExistingCustomer = async (form) => {
  try {
    const response = await useAxiosPost("/users/validate-field", form);
    return response.data.payload;
  } catch (error) {
    console.log("Error in findExistingCustomer", error);
  }
};

export const fetchMyGuestAccount = async (email) => {
  try {
    const response = await useAxiosGet(`/users/get-guest-account?email=${email}`);
    if (response.data.success) {
      return response.data.payload;
    }
    return null;
  } catch (error) {
    console.log("Error in fetchMyGuestAccount", error);
    return null;
  }
};

export const establishSlackConnection = (form) => async (_, getState) => {
  try {
    const user = getState().firebase.profile;
    const response = await useAxiosPost("/users/establish-slack-connection", { form, user });
    if (response.data.success) {
      toastr.success("Success", "Slack connection established");
    }
  } catch (error) {
    console.log("Error in establishSlackConnection", error);
    toastr.error("Error", "Something went wrong");
  }
};

export const linkMyAccounts = async (guestUserId, userId) => {
  try {
    const response = await useAxiosPost("/users/merge-accounts", { guestUserId, userId });
    if (!response?.data?.success) throw response.data.error ?? "Something went wrong";

    toastr.success("Success", "Accounts linked");
    return response.data.success;
  } catch (error) {
    console.log("Error in fetchMyGuestAccount", error);
    toastr.error("Error", "Failed to link accounts");
    return false;
  }
};

export const userLogin = async (profile) => {
  if (!profile?.uid) return;
  try {
    segmentTrack("User Session Start");
    segmentIdentify({
      email: profile.email,
      userType: profile.userType,
    });
    Kustomer.describe();
    await useAxiosGet(`/users/user-login/${profile.uid}`);
  } catch (error) {
    //;
  }
};

export const userCloseSession = () => {
  segmentTrack("User Session Close");
};

// Upgrade warrior's tier from admin
export const updateWarriorTier = async (warriorId, tier) => {
  if (!warriorId || !tier) return;
  try {
    await useAxiosPost(`/users/update-warrior-tier`, { warriorId, tier });
    toastr.success("Warrior Tier Updated");
  } catch (error) {
    console.error("Could not update warrior tier", error);
    toastr.error("Could not update warrior tier, please try refreshing the page and try");
  }
};
