import { constructDumpsterPrice } from "../helpers/dumpsters";
import { DumpsterPickupType, DumpsterSizeType } from "../interfaces/dumpster";

// Basic yards
export const dumpsterYards: Array<number> = [5, 10, 20, 30, 40];

// Dumpster sizes contruct
export const dumpsterSize: DumpsterSizeType = dumpsterYards.reduce((acc, key) => ({
  ...acc, 
  [key]: {
    label: `${key} Yard`,
    value: key,
  }
}), {});

// Default base prices
export const dumpsterBasePrice: { [key: number]: number } = {
  [dumpsterSize[5].value]: 30000,
  [dumpsterSize[10].value]: 40000,
  [dumpsterSize[20].value]: 50000,
  [dumpsterSize[30].value]: 60000,
  [dumpsterSize[40].value]: 70000,
};

// CP = WP * 1.33
// temp form factor change on 12/10/2022
export const dumpster_WP_CP_FormFactor: number = 1.20;

// $10 per day for each day between appointment and pickup date
export const everydayPickupPrice: number = 1000;

// Next booking date option is equivalent to 20 days
export const nextBookingDays = 20;

export enum Pickup {
  NextBooking = "next-booking",
  ThreeDays = "3-days-later",
  SevenDays = "7-days-later",
  Manual = "manual",
}

// Pickup date options
export const dumpsterPickups: DumpsterPickupType = {
  [Pickup.NextBooking]: {
    label: "Next booking",
    shortLabel: "Next booking",
    value: Pickup.NextBooking,
    days: 20,
  },
  [Pickup.ThreeDays]: {
    label: "3 Days Later",
    shortLabel: "3 Days",
    value: Pickup.ThreeDays,
    days: 3,
  },
  [Pickup.SevenDays]: {
    label: "1 Week Later",
    shortLabel: "1 Week",
    value: Pickup.SevenDays,
    days: 7,
  },
  [Pickup.Manual]: {
    label: "Manual",
    shortLabel: "Manual",
    value: "manual",
    days: 0,
  },
};

export const constructDumpsterSizes = (days: number, basePrice: typeof dumpsterBasePrice | undefined) => {
  const dumpsterPrice = constructDumpsterPrice(basePrice, days);
  const getPrice = (yardage: number) => dumpsterPrice[yardage][days] ?? dumpsterPrice[yardage]["base"];

  return [
    {
      id: "1",
      description: dumpsterSize[5].label,
      price: getPrice(dumpsterSize[5].value),
      size: "small",
      info: ["5 - 10 garbage bags + 2 dressers or desks", "or 1 small room worth of items", "0.5 tons of waste"],
    },
    {
      id: "2",
      description: dumpsterSize[10].label,
      price: getPrice(dumpsterSize[10].value),
      size: "small",
      info: ["1 medium size room worth of items or Concret or Dirt", "or 5 dressers + 20 garbage bags + 1 sofa", "1 ton of waste"],
    },
    {
      id: "3",
      description: dumpsterSize[20].label,
      price: getPrice(dumpsterSize[20].value),
      size: "large",
      info: ["Recommended for a small home renovation", "40+ garbage bags", "2 tons of waste"],
    },
    {
      id: "4",
      description: dumpsterSize[30].label,
      price: getPrice(dumpsterSize[30].value),
      size: "large",
      info: ["Recommended for a medium home renovation", "or 100+ Pallets", "3 tons of waste"],
    },
    {
      id: "5",
      description: dumpsterSize[40].label,
      price: getPrice(dumpsterSize[40].value),
      size: "large",
      info: ["Recommended for warehouses or a house clean out renovation", "Cardboard + pallets + tables + desk ", "4 tons of waste"],
    },
  ];
};

export const materials = [
  {
    id: "1",
    description: "Cardboard and wooden pallets",
    isHelperNeeded: false,
    percentage: 0,
  },
  {
    id: "2",
    description: "Construction material (sand, concrete, wood)",
    isHelperNeeded: false,
    percentage: 10,
  },
  {
    id: "3",
    description: "Food waste (wet and dry)",
    isHelperNeeded: false,
    percentage: 20,
  },
  {
    id: "4",
    description: "Other",
    isHelperNeeded: false,
    percentage: 0,
  },
];
