import lookup from "./lookup";

const isObject = (item) => typeof item === "object" && item !== null;

// sorts alphabetically (A-Z)
export const sortA_Z = (list, key) =>
  list.sort(
    (a, b) =>
      (isObject(a) ? lookup(a, key) : a > isObject(b) ? lookup(b, key) : b && 1) ||
      (isObject(b) ? lookup(b, key) : b > isObject(a) ? lookup(a, key) : a && -1) ||
      0
  );

// sorts alphabetically (Z-A)
export const sortZ_A = (...args) => sortA_Z(...args).reverse();

// sorts in Desc order by default
export const sortDate = (list, key, desc = true) =>
  list.sort(
    (a, b) =>
      new Date(isObject(desc ? b : a) ? lookup(desc ? b : a, key) : desc ? b : a) -
      new Date(isObject(desc ? a : b) ? lookup(desc ? a : b, key) : desc ? a : b)
  );
