import userTypes from "@store/constants/userTypes";
import { updateItem } from "../utils";

const INITIAL_STATE = {
  warriors: {
    data: [],
    page: {},
  },
  customers: {
    data: [],
    page: {},
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userTypes.SET_CUSTOMERS:
      return {
        ...state,
        customers: {
          ...state.customers,
          ...action.payload,
        },
      };
    case userTypes.SET_WARRIORS:
      return {
        ...state,
        warriors: {
          ...state.warriors,
          ...action.payload,
        },
      };
    case userTypes.UPDATE_WARRIOR:
      return {
        ...state,
        warriors: {
          ...state.warriors,
          data: updateItem(state.warriors.data, action.payload),
        },
      };
    default:
      return state;
  }
};
