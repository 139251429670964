import { appCheck } from "../config/firebaseConfig";
import { getToken } from "@firebase/app-check";
import { segmentTrack } from "./segment";

export const getAppCheckToken = async () => {
  let appCheckTokenResponse;
  try {
    appCheckTokenResponse = await getToken(appCheck, true);
  } catch (error) {
    segmentTrack("App Check Token Error", { error });
    return;
  }
  if (appCheckTokenResponse?.token) {
    return appCheckTokenResponse.token;
  } else {
    return null;
  }
};
