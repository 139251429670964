import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/app-check";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();

if (app) {
  auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  firestore.settings({ timestampsInSnapshots: true, merge: true });
}

if (process.env.REACT_APP_ENV === "local") {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_FIREBASE_APP_CHECK_KEY),
  isTokenAutoRefreshEnabled: true,
});

if (window.location.hostname === "localhost") {
  auth.useEmulator("http://localhost:9099/");
  firestore.useEmulator("localhost", 8080);
  storage.useEmulator("localhost", 9199);
}

export { auth, firestore, firebase, storage, appCheck };
