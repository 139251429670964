import TagManager from "react-gtm-module";
import { isProduction } from "@constants/environments";
import { isDevelopment } from "./constants/environments";

const gSegmentId = process.env.REACT_APP_GOOGLE_SEGMENT_ID;
const useProofId = process.env.REACT_APP_USE_PROOF_ID;

const headTag = document.head;
const bodyTag = document.body;

const createElement = (element, attributes = {}) => {
  const el = document.createElement(element);
  for (const attr in attributes) {
    el[attr] = attributes[attr];
  }
  return el;
};

const loadFacebook = () => {
  const metascript = createElement("meta");
  metascript.name = "facebook-domain-verification";
  metascript.content = "asveoqxrfjc8kzkgklpbflfuoajjx6";
  headTag.append(metascript);
};

const loadScript = (attributes, name) => {
  const script = createElement("script", attributes);
  headTag.append(script);
};

const loadScriptToBody = (attributes) => {
  const script = createElement("script", attributes);
  bodyTag.append(script);
};
const loadNoScriptToBody = (attributes) => {
  const script = createElement("noscript", attributes);
  bodyTag.append(script);
};

export const loadHelloBar = () => {
  if (!isProduction) return;
  loadScriptToBody({
    src: "https://my.hellobar.com/4631e149a4e2eb7400a020173ea1ea7383f51659.js",
    async: true,
  });
};

const loadUseProof = () => {
  loadScript({
    src: `https://cdn.useproof.com/proof.js?acc=${useProofId}`,
    defer: true,
  });
};

const loadTrustPilot = () => {
  loadScript({
    src: "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js",
    async: true,
  });
};

const loadSegment = () => {
  if (!gSegmentId) {
    return;
  }
  loadScript({
    text: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${gSegmentId}";analytics.SNIPPET_VERSION="4.13.2";
    analytics.load("${gSegmentId}");
    }}();`,
  });
};

const loadGTag = () => {
  loadScript({
    text: `window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({originalLocation: document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search});`,
  });
  const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
  TagManager.initialize({ gtmId });
};

const loadZoomInfo = () => {
  loadScript({
    src: "https://ws.zoominfo.com/pixel/621d1640695c29001c4fdef6",
    async: true,
  });
  loadNoScriptToBody({
    text: `<img src="https://ws.zoominfo.com/pixel/621d1640695c29001c4fdef6"
    width="1" height="1" style="display: none;"
    alt="websights" />`,
  });
};

export const loadKustomerChat = () => {
  const kustomerKey = process.env.REACT_APP_KUSTOMER_API_KEY;
  const kustomerBrandId = process.env.REACT_APP_KUSTOMER_BRAND_ID;
  loadScriptToBody({
    text: `window.addEventListener('kustomerLoaded', function() {
      Kustomer.start({
        brandId: '${kustomerBrandId}'
      });
    });
    var script = document.createElement('script');
    script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js';
    script.setAttribute('data-kustomer-api-key', '${kustomerKey}');
    window.document.body.appendChild(script);`,
  });
};

export const loadAllScripts = () => {
  if (isProduction) {
    loadGTag();
    loadSegment();
    loadKustomerChat();
    loadTrustPilot();
    loadZoomInfo();
    loadFacebook();
    loadUseProof();
  }
  if (isDevelopment) {
    loadKustomerChat();
  }
};
