import dumpSitesTypes from "../constants/dumpSitesTypes";
import { addItem, updateItem, deleteItem } from "../utils";

const INITIAL_STATE = {
  dumpSites: [],
  selectedDumpSite: null,
  isLoading: true,
};

const dumpSitesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case dumpSitesTypes.FETCH_DUMPSITES:
      return {
        ...state,
        dumpSites: [...action.payload],
        isLoading: false,
      };
    case dumpSitesTypes.ADD_DUMPSITE:
      return {
        ...state,
        dumpSites: addItem(state.dumpSites, action.payload),
      };
    case dumpSitesTypes.UPDATE_DUMPSITE: {
      return {
        ...state,
        dumpSites: updateItem(state.dumpSites, action.payload),
      };
    }
    case dumpSitesTypes.DELETE_DUMPSITE: {
      return {
        ...state,
        dumpSites: deleteItem(state.dumpSites, action.payload),
      };
    }
    case dumpSitesTypes.SET_SELECTED_DUMPSITE:
      return {
        ...state,
        selectedDumpSite: action.payload,
      };
    case dumpSitesTypes.RESET_SELECTED_DUMPSITE:
      return {
        ...state,
        selectedDumpSite: null,
      };
    case dumpSitesTypes.SET_DUMPSITES_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case dumpSitesTypes.RESET_DUMPSITES_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default dumpSitesReducer;
