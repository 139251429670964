import { firebase } from "@config/firebaseConfig";
import { isProduction, isStaging } from "@constants/environments";
import { genCustomUID } from "../shared/utils/generateUID";

export const segmentIdentify = (traits, uidOverride = undefined) => {
  if (!isProduction) {
    return;
  }
  const uid = uidOverride ?? firebase.auth().currentUser?.uid;
  const idTraits = traits ?? {};
  if (uid) {
    analytics.identify(uid, { ...idTraits });
  } else {
    analytics.identify({ ...idTraits });
  }
};

/* pass in the Segment identifier (name) and fields */
export const segmentTrack = (name, payload, track = true) => {
  if (!track) return;

  let sessionTrack = sessionStorage.getItem("tw-session-id");
  if (!sessionTrack) {
    sessionTrack = genCustomUID();
    sessionStorage.setItem("tw-session-id", sessionTrack);
  }

  const gclid = sessionStorage.getItem("gclid-track");
  const identified = sessionStorage.getItem("segment-identified");
  const version = isProduction ? "react-panda" : "react-dev";
  const uid = firebase.auth().currentUser?.uid ?? "unregistered";
  let fullPayload = {
    React_id: uid,
    version: version,
    gclid: gclid ?? "",
    reactSessionId: sessionTrack,
  };
  if (payload) {
    fullPayload = {
      ...fullPayload,
      ...payload,
    };
  }

  if (!isProduction) {
    // eslint-disable-next-line
    console.log(`Segment: ${name}`, fullPayload);
    return;
  }

  if (isProduction) {
    if (identified !== "true" && name === "Service Selected") {
      segmentIdentify({ gclid: gclid });
      sessionStorage.setItem("segment-identified", "true");
    }
  }

  // analytics?.track(name, fullPayload);

  if (isProduction) {
    analytics.track(name, fullPayload);
    window.dataLayer.push({
      event: name?.split(" ")?.join("_")?.toLowerCase(),
      ...fullPayload,
    });
  }
};

let timerID;
// push to segment with delay
export const pushToSegment = (event, data) => {
  clearTimeout(timerID);
  timerID = setTimeout(() => {
    segmentTrack(event, data);
  }, 1000);
};
