import bookingConstants from "@store/constants/bookingTypes";
import { addItem, updateItem, deleteItem, mergeItem } from "../utils";

const INITIAL_STATE = {
  myBookings: [],
  selectedBooking: null,
  allBookings: [],
  isLoading: false,
  isLoadingAll: false,
  recurringBookings: [],
  selectedRecurringBooking: null,
  slotBookings: [],
  fetchingBookings: false,
  draftBooking: null,
  bookings: {
    data: [],
    page: {},
  },
  availableTasks: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case bookingConstants.FETCH_BOOKINGS:
      return {
        ...state,
        bookings: [...action.payload],
      };
    case bookingConstants.FETCH_AVAILABLE_TASKS:
      return {
        ...state,
        availableTasks: [...action.payload],
      };
    case bookingConstants.CREATE_BOOKING:
      return {
        ...state,
        newBooking: { ...action.payload },
      };
    case bookingConstants.CREATE_BOOKING_ERROR:
      return {
        ...state,
        newBooking: { ...action.payload },
      };
    case bookingConstants.FETCH_MY_ORDERS:
    case bookingConstants.FETCH_MY_BOOKINGS:
      return {
        ...state,
        myBookings: [...action.payload],
      };
    case bookingConstants.FETCH_MY_ORDERS_ERROR:
    case bookingConstants.FETCH_MY_BOOKINGS_ERROR:
      return {
        ...state,
        myBookings: [],
      };
    case bookingConstants.ACTIVE_BOOKING:
      return {
        ...state,
        selectedBooking: action.selectedBooking,
      };
    case bookingConstants.RELEASE_ACTIVE_BOOKING:
      return {
        ...state,
        selectedBooking: null,
      };
    case bookingConstants.RELEASE_ACTIVE_RECURRING_BOOKING:
      return {
        ...state,
        selectedRecurringBooking: null,
      };
    case bookingConstants.FETCH_ALL_BOOKINGS:
      return {
        ...state,
        allBookings: mergeItem(state.allBookings, action.payload),
      };
    case bookingConstants.SET_BOOKINGS:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          ...action.payload,
        },
      };
    case bookingConstants.ADD_BOOKING:
      return {
        ...state,
        allBookings: addItem(state.allBookings, action.payload),
      };
    case bookingConstants.UPDATE_BOOKING:
      return {
        ...state,
        allBookings: updateItem(state.allBookings, action.payload),
      };
    case bookingConstants.DELETE_BOOKING:
      return {
        ...state,
        allBookings: deleteItem(state.allBookings, action.payload),
      };
    case bookingConstants.SET_BOOKING_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case bookingConstants.RESET_BOOKING_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case bookingConstants.SET_BOOKINGS_LOADING:
      return {
        ...state,
        isLoadingAll: true,
      };
    case bookingConstants.RESET_BOOKINGS_LOADING:
      return {
        ...state,
        isLoadingAll: false,
      };

    case bookingConstants.FETCHING_BOOKINGS:
      return {
        ...state,
        fetchingBookings: action.payload,
      };
    case bookingConstants.FETCH_ALL_RECURRING_BOOKINGS:
      return {
        ...state,
        recurringBookings: mergeItem(state.recurringBookings, action.payload),
      };
    case bookingConstants.ADD_RECURRING_BOOKING:
      return {
        ...state,
        recurringBookings: addItem(state.recurringBookings, action.payload),
      };
    case bookingConstants.UPDATE_RECURRING_BOOKING:
      return {
        ...state,
        recurringBookings: updateItem(state.recurringBookings, action.payload),
      };
    case bookingConstants.DELETE_RECURRING_BOOKING:
      return {
        ...state,
        recurringBookings: deleteItem(state.recurringBookings, action.payload),
      };
    case bookingConstants.SET_SELECTED_RECURRING_BOOKING:
      return {
        ...state,
        selectedRecurringBooking: action.payload,
      };
    case bookingConstants.SET_UPDATED_BOOKING:
      return {
        ...state,
        updatedBooking: action.payload,
      };
    case bookingConstants.SET_SLOT_BOOKINGS:
      return {
        ...state,
        slotBookings: action.payload ?? [],
      };
    case bookingConstants.SET_DRAFT_BOOKING:
      return {
        ...state,
        draftBooking: action.payload,
      };
    default:
      return state;
  }
};
