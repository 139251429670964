const bookingTypes = {
  SET_BOOKING_LOADING: "SET_BOOKING_LOADING",
  RESET_BOOKING_LOADING: "RESET_BOOKING_LOADING",
  SET_BOOKINGS_LOADING: "SET_BOOKINGS_LOADING",
  RESET_BOOKINGS_LOADING: "RESET_BOOKINGS_LOADING",

  SET_BOOKINGS: "SET_BOOKINGS",
  FETCH_BOOKINGS: "FETCH_BOOKINGS",
  FETCH_AVAILABLE_TASKS: "FETCH_AVAILABLE_TASKS",
  FETCH_BOOKINGS_ERROR: "FETCH_BOOKINGS_ERROR",
  FETCH_MY_BOOKINGS: "FETCH_MY_BOOKINGS",
  FETCH_MY_BOOKINGS_ERROR: "FETCH_MY_BOOKINGS_ERROR",
  CREATE_BOOKING: "CREATE_BOOKING",
  CREATE_BOOKING_ERROR: "CREATE_BOOKING_ERROR",
  ADD_BOOKING: "ADD_BOOKING",
  UPDATE_BOOKING: "UPDATE_BOOKING",
  UPDATE_BOOKING_ERROR: "UPDATE_BOOKING_ERROR",
  DELETE_BOOKING: "DELETE_BOOKING",
  DELETE_BOOKING_ERROR: "DELETE_BOOKING_ERROR",
  SET_CLAIM_TASK_ERROR: "SET_CLAIM_TASK_ERROR",
  SET_CLAIM_TASK: "SET_CLAIM_TASK_ERROR",
  FETCH_MY_ORDERS: "FETCH_MY_ORDERS",
  FETCH_MY_ORDERS_ERROR: "FETCH_MY_ORDERS_ERROR",
  ACTIVE_BOOKING: "ACTIVE_BOOKING",
  RELEASE_ACTIVE_BOOKING: "RELEASE_ACTIVE_BOOKING",
  RELEASE_ACTIVE_RECURRING_BOOKING: "RELEASE_ACTIVE_RECURRING_BOOKING",
  FETCH_ALL_BOOKINGS: "FETCH_ALL_BOOKINGS",
  FETCH_ALL_BOOKINGS_ERROR: "FETCH_ALL_BOOKINGS_ERROR",

  FETCHING_BOOKINGS: "FETCHING_BOOKINGS",

  FETCH_ALL_RECURRING_BOOKINGS: "FETCH_ALL_RECURRING_BOOKINGS",
  SET_SELECTED_RECURRING_BOOKING: "SET_SELECTED_RECURRING_BOOKING",
  ADD_RECURRING_BOOKING: "ADD_RECURRING_BOOKING",
  UPDATE_RECURRING_BOOKING: "UPDATE_RECURRING_BOOKING",
  DELETE_RECURRING_BOOKING: "DELETE_RECURRING_BOOKING",
  SET_UPDATED_BOOKING: "SET_UPDATED_BOOKING",

  SET_SLOT_BOOKINGS: "SET_SLOT_BOOKINGS",
  SET_DRAFT_BOOKING: "SET_DRAFT_BOOKING",
};

export default bookingTypes;
