/* eslint-disable no-console*/
import { useAxiosPost } from "@axios";

export const checkFeatureFlag = async (flag, data, value, bypassLocal = false) => {
  try {
    if (flag in window.sessionStorage && !bypassLocal) {
      const isUndefined = window.sessionStorage[`ld-flag-${flag}`] === "undefined";
      if (!isUndefined) return JSON.parse(window.sessionStorage[`ld-flag-${flag}`]);
    }
    const response = await useAxiosPost("/feature_flags/check-flag", { flag, data, value });
    // if response from backend is undefined, use default value
    const retVal = response?.data?.isValid ?? value;
    window.sessionStorage.setItem(`ld-flag-${flag}`, retVal);
    return retVal;
  } catch (error) {
    console.log("Error checkFeatureFlag", error);
    return value;
  }
};

export const closeLDFlags = async () => {
  try {
    await useAxiosPost("/feature_flags/close-flags");
    return;
  } catch (error) {
    console.log("Error closeLDFlags", error);
  }
};
